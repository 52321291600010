<template>
  <div class="container">
    <div class="text-right my-4">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>
      <div class="text-left">
        <v-container fluid>
          <v-row>
            <!-- <v-col cols="6"> -->
            <form>
              <v-row>
                <v-col cols="2">
                  <v-text-field
                    v-model="query"
                    label="Enter Endpoint Name"
                    required>
                    <!-- v-on:keyup="searchEndpointName" -->
                  </v-text-field>
                </v-col>
                <v-col cols="2" class="my-4">
                  <v-select
                    :items="panel_data"
                    :menu-props="{ top: true, offsetY: true }"
                    clearable
                    label="Panels"
                    v-model="p_id"
                    dense
                    v-on:change="panel_modules(p_id)"
                  ></v-select>
                </v-col>
                <v-col cols="2" class="my-4">
                <v-autocomplete
                          :items="panel_modules_data"
                          clearable
                          item-text="text"
                          item-value="value"
                          label= "Select Parent Module"
                          :disabled="!isModuleDdEditing"
                          v-model="m_id"
                          dense
                ></v-autocomplete>
                </v-col>
                <v-col cols="2">
                  <v-select
                    :items="searchService"
                    :menu-props="{ top: true, offsetY: true }"
                    clearable
                    label="Services"
                    v-model="s_id"
                  ></v-select>
                </v-col>
                <!-- </v-col> -->
                <v-col cols="1" class="my-4">
                  <v-btn small color="primary" @click="searchEndpoints()">
                    Search
                  </v-btn>
                </v-col>
                <v-col cols="1" class="my-4">
                  <!-- <v-col cols="1" class="mt-4" > -->
                  <v-btn small color="primary" @click="resetSearch()">
                    Reset
                  </v-btn>
                </v-col>

                <v-col cols="1" class="my-4">
                  <template>
                    <v-dialog
                      v-model="dialog"
                      persistent
                      transition="dialog-bottom-transition"
                      max-width="700"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          class="mx-2"
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          @click="add_prerequisites()"
                          v-show="checkIfOperationExistForModule('add')"
                        >
                          Add
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">Add Endpoint</span>
                        </v-card-title>
                        <v-form
                          ref="form"
                          @submit="addEndpointData"
                          method="post"
                          v-model="valid"
                          lazy-validation>
                          <v-container class="px-50 v-card-padding" fluid>
                            <v-text-field
                              v-model="addEndpoint.endpointName"
                              label="Endpoint Name"
                              :rules="nameRules"
                              required
                            ></v-text-field>
                            <div style="color: red">
                              {{ message1 }}
                            </div>
                            <v-text-field
                              v-model="addEndpoint.endpoint_slug"
                              label="Endpoint Slug"
                            ></v-text-field>
                            <v-text-field
                              v-model="addEndpoint.endpoint_desc"
                              label="Endpoint Desc"
                            ></v-text-field>
                            <v-select
                              :items="service_data"
                              :rules="service_required"
                              required
                              clearable
                              label="Select Service"
                              v-model="addEndpoint.service"
                            ></v-select>
                            <v-container class="px-50 v-card-padding" fluid>
                            <v-select
                              :items="panel_data"
                              :menu-props="{ top: true, offsetY: true }"
                              clearable
                              label="Panels"
                              v-model="addEndpoint.panel"
                              dense
                              v-on:change="panel_modules(addEndpoint.panel)"
                            ></v-select>
                              <v-autocomplete
                                :items="panel_modules_data"
                                label="Select Parent Module"
                                :disabled="!isModuleDdEditing"
                                v-model="parent_module_id"
                                item-value="value"
                                dense
                                v-on:change="get_child_modules()"
                              ></v-autocomplete>
                              <span v-if="is_parent_module_not_selected" style="color:red">
                                  Parent Module Required.
                              </span>
                              <v-autocomplete
                                :items="child_modules"
                                label="Select Module"
                                :disabled="!isChildModuleDdEditing"
                                v-model="module_id"
                                dense
                              ></v-autocomplete>
                              <v-select
                                :items="operation_data"
                                label="Select Operation"
                                v-model="operation_ids"
                                multiple
                                dense
                                chips
                              ></v-select>
                              <v-card-actions>
                                <!-- <v-spacer></v-spacer> -->
                                <v-btn
                                  small
                                  color="blue darken-1"
                                  :disabled="operation_ids.length==0"
                                  @click="add_module_operations()"
                                >
                                  Add
                                </v-btn>
                              </v-card-actions>
                              <v-simple-table dense  class="elevation-1" v-if="addEndpoint.module_operations.length">
                              <template v-slot:default>
                                <thead  style="background-color:blue">
                                  <tr>
                                    <th class="text-left">
                                      Panel
                                    </th>
                                    <th class="text-left">
                                      Parent Module
                                    </th>
                                    <th class="text-left">
                                      Module
                                    </th>
                                    <th class="text-left">
                                      Operations
                                    </th>
                                    <th class="text-left">
                                      Actions
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="item in addEndpoint.module_operations"
                                    :key="item.module_id"
                                  >
                                    <td>{{ get_panel_name(item.parent_module_id)}}</td>
                                    <td>{{ get_module_name(item.parent_module_id) }}</td>
                                    <td>{{ get_module_name(item.module_id) }}</td>
                                    <td>{{ get_operation_name(item.operation_ids) }}</td>
                                    <td>
                                      <v-icon
                                              medium
                                              color="grey"
                                              @click="remove_from_add_operations(item.module_id)"
                                            >
                                            mdi-delete
                                          </v-icon>
                                      <!-- <v-btn color="primary" small @click.stop="remove_from_add_operations(item.module_id)">
                                        Delete
                                      </v-btn> -->
                                      </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                            </v-container>
                            <v-select
                              :items="http_methods"
                              label="Select HTTP Methods"
                              v-model="addEndpoint.http_methods"
                              multiple
                              dense
                              chips
                            ></v-select>
                          </v-container>
                          <v-container class="px-50" fluid> </v-container>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              :disabled="!valid"
                              color="blue darken-1"
                              type="submit"
                              @click="validate"
                            >
                              Submit
                            </v-btn>
                            <v-btn
                              class="close_button mx-2"
                              text
                              @click="reset"
                            >
                              Close
                            </v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                  </template>
                </v-col>
                <v-col cols="1" class="mt-4">
                  <v-dialog max-width="600" v-model="dialog2">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        v-show="checkIfOperationExistForModule('delete')"
                        >Delete</v-btn
                      >
                    </template>
                    <div v-if="deleteItems.length > 0">
                      <template>
                        <v-card>
                          <v-card-text>
                            <div class="text-h3 pa-12">
                              Are you sure you want to delete all the selected
                              Record(s)?
                            </div>
                          </v-card-text>
                          <v-card-actions class="justify-end">
                            <v-form ref="form" method="post">
                              <v-btn
                                text
                                class="confirm_button mx-2"
                                v-on:click="dialog2 = false"
                                @click="deleteEndpoint"
                                >Yes</v-btn
                              >
                            </v-form>
                            <v-btn
                              class="close_button mx-2"
                              text
                              @click="dialog2 = false"
                            >
                              Close
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </template>
                    </div>
                    <div v-if="deleteItems.length == 0">
                      <template>
                        <v-card>
                          <v-card-text class="center">
                            <div class="text-h3 pa-12">
                              Please Select Record(s) to delete
                            </div>
                          </v-card-text>
                          <v-card-actions class="justify-end">
                            <v-btn
                              class="close_button"
                              text
                              @click="dialog2 = false"
                            >
                              Close
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </template>
                    </div>
                  </v-dialog>
                </v-col>
              </v-row>
            </form>
          </v-row>
        </v-container>
      </div>
    </div>
    <div class="text-right"></div>
    <!-- v-data-table  -->
    <v-row>
      <v-col cols="12">
        <div class="tableWrapper">
          <div id="select-all-checkbox">
            <v-checkbox
              ref="n"
              v-model="allSelected"
              @click="selectAll"
            ></v-checkbox>
          </div>
          <v-data-table
            :headers="headers"
            item-key="product_id"
            :items="endpoint_data"
            :options.sync="options"
            :server-items-length="total"
            :loading="isLoading"
            class="elevation-1"
            hide-default-footer
          >
            <template v-slot:item.selectCheckbox="{ item }">
              <v-checkbox
                ref="n"
                v-model="deleteItems"
                :value="item.endpoint_id"
                :key="item.endpoint_id + item.module_id"
                @click="addToDelete($event, item.endpoint_id)"
              ></v-checkbox>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                medium
                color="grey"
                @click="addToUpdate($event, item.endpoint_id)"
                v-show="checkIfOperationExistForModule('edit')"
              >
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
          <div class="text-center pt-4 pb-4">
            <Pagination
              :isloading="isLoading"
              :startRecord="startRecord"
              :currentPage="currentPage"
              :lastPage="lastPage"
              :lastRecord="lastRecord"
              :totRecords="totRecords"
              :isCurrentPageClass="isCurrentPageClass"
              :perpage="perpage"
              :getLastPageClass="getLastPageClass"
              :totPage="totPage"
              :getPages="getPages"
              @handlePerPage="handlePerPage"
              @paginate="paginate"
              @last="last"
              @getDataByPage="getDataByPage"
              :showPerPage="showPerPage"
            />
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="toggleUpdateModal" max-width="600">
        <template>
          <v-card>
            <v-card-title>
              <span class="headline">Update Module Endpoint</span>
            </v-card-title>
            <v-form ref="form" 
                @submit="updateEndpointData" 
                method="put"
                v-model="valid"
                lazy-validation>
                <v-container class="px-50" fluid>
                <v-text-field
                  v-model="updateEndpoint.endpointName"
                  label="Endpoint Name"
                  required
                  :rules="nameRules"
                ></v-text-field>
                <div style="color: red">
                  {{ upmessage1 }}
                </div>
                <v-text-field
                  v-model="updateEndpoint.endpoint_slug"
                  label="Endpoint Slug"
                ></v-text-field>
                <v-text-field
                  v-model="updateEndpoint.endpoint_desc"
                  label="Endpoint Desc"
                ></v-text-field>
                <v-select
                  :items="service_data"
                  label="Select Service"
                  :rules="service_required"
                  required
                  clearable
                  v-model="updateEndpoint.service"
                ></v-select>
              </v-container>
              <v-container class="px-50 v-card-padding" fluid>
              <v-select
                  :items="panel_data"
                  :menu-props="{ top: true, offsetY: true }"
                  clearable
                  label="Panels"
                  v-model="updateEndpoint.panel"
                  dense
                  v-on:change="panel_modules(updateEndpoint.panel)"
              ></v-select>
              <v-autocomplete
                                :items="panel_modules_data"
                                label="Select Parent Module"
                                clearable
                                :disabled="!isModuleDdEditing"
                                v-model="parent_module_id"
                                item-value="value"
                                dense
                                v-on:change="get_child_modules()"
              ></v-autocomplete>
              <span v-if="is_parent_module_not_selected" style="color:red">
                  Parent Module Required.
              </span>
              <v-autocomplete
                  :items="child_modules"
                  label="Select Module"
                  clearable
                  :disabled="!isChildModuleDdEditing"
                  v-model="module_id"
                  dense
              ></v-autocomplete>
              <v-select
                  :items="operation_data"
                  label="Select Operation"
                  clearable
                  v-model="operation_ids"
                  multiple
                  dense
                  chips
                ></v-select>
                <v-card-actions>
                  <!-- <v-spacer></v-spacer> -->
                  <v-btn
                    small
                    color="blue darken-1"
                    :disabled="operation_ids.length==0"
                    @click="update_module_operations()"
                  >
                    Add
                  </v-btn>
                </v-card-actions>
                <v-simple-table dense  class="elevation-1" v-if="updateEndpoint.module_operations.length">
                <template v-slot:default>
                  <thead  style="background-color:blue">
                    <tr>
                      <th class="text-left">
                        Panel
                      </th>
                      <th class="text-left">
                        Parent Module
                      </th>
                      <th class="text-left">
                        Module
                      </th>
                      <th class="text-left">
                        Operations
                      </th>
                      <th class="text-left">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in updateEndpoint.module_operations"
                      :key="item.module_id"
                    >
                      <td>{{ get_panel_name(item.parent_id) }}</td>
                      <td>{{ get_module_name(item.parent_id) }}</td>
                      <td>{{ get_module_name(item.module_id) }}</td>
                      <td>{{ get_operation_name(item.operation_ids) }}</td>
                      <td>
                        <v-icon
                              medium
                              color="grey"
                              @click="remove_from_update_operations(item.module_id)"
                            >
                            mdi-delete
                          </v-icon>
                        <!-- <v-btn color="primary" small @click.stop="remove_from_update_operations(item.module_id)">
                          Delete
                        </v-btn> -->
                        </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
                <v-select class="mt-10"
                  :items="http_methods"
                  label="Select HTTP Methods"
                  v-model="updateEndpoint.http_methods"
                  multiple
                  dense
                  chips
                ></v-select>
              </v-container>
              <v-container class="px-50" fluid>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    :disabled="!valid"
                    @click="validate"
                    type="submit"
                  >
                    Update
                  </v-btn>
                  <v-btn
                    class="close_button"
                    text
                    @click="clearData"
                  >
                    Close
                  </v-btn>
                </v-card-actions>
              </v-container>
            </v-form>
          </v-card>
        </template>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import Index from "./Index.vue";
import Buttons from "./component/Buttons.vue";
import Item from "../../components/base/Item.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import Pagination from "./component/Pagination.vue";

export default {
  components: { Index, Buttons, Loading, Pagination },
  data() {
    return {
      toggleUpdateModal: false,
      //////table data starts here////
      selected: [],
      options: {},
      sort: "",
      total: 0,
      editReportMode: false,
      fromDateMenu: false,
      fromDateVal: null,
      //  minDate: "2020-01-05",
      //  maxDate: "2019-08-30",
      headers: [
        {
          text: "",
          value: "selectCheckbox",
          width: "50px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Endpoint Name",
          value: "endpoint_name",
          width: "200px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Panel Name",
          value: "panel_name",
          width: "150px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Module Name",
          value: "module_name",
          width: "200px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Service Name",
          value: "service_name",
          width: "150px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Modified Date",
          value: "modified_date",
          width: "150px",
          sortable: false,
          class: "v-data-table-header",
        },

        {
          text: "Action",
          value: "actions",
          width: "50px",
          sortable: false,
          class: "v-data-table-header",
        },
      ],
      ////// table data ends here
      valid: true,
      checkbox: false,
      endpointName: [],
      endpoint_data: [],
      service_data: [],
      parent_module_data: [],
      all_module_data: [],
      operation_data: [],
      http_methods: [],
      module_parent_id: 0,
      isLoading: false,
      fullPage: true,
      dialog: false,
      dialog1: false,
      dialog2: false,
      deleteItems: [],
      checked_operationId: [],
      checkcedstr: "",
      message1: "",
      message2: "",
      upmessage1: "",
      upmessage2: "",
      allSelected: false,
      module_id: null,
      operation_ids: [],
      parent_module_id: "",
      addEndpoint: {
        endpointName: null,
        service: null,
        panel: null,
        http_methods: [],
        module_operations: [],
        endpoint_slug: "",
        endpoint_desc: "",
      },
      updateEndpoint: {
        endpointId: null,
        endpointName: null,
        service: null,
        panel: null,
        module_operations: [],
        http_methods: [],
        endpoint_slug: "",
        endpoint_desc: "",
      },
      child_modules: [],
      panel_required: [value => !!value || 'Panel is required.'],        
      parent_module_required: [value => !!value || 'Parent Module is required.'],
      module_required: [value => !!value || 'Module is required.'],
      operation_required: [value => !!value || 'Operation is required.'],
      service_required: [value => !!value || 'Service is required.'],
      checked: false,
      nameRules: [(v) => !!v || "Name is required"],
      addEndpointlabel: null,
      query: "",
      m_id: "",
      s_id: "",
      p_id: "",
      panel_id: "",
      is_parent_module_not_selected:false,
      panel_modules_data: [],
      searchService: [],
      panel_data: [],
      parent_modules_data: [],
      isModuleDdEditing: false,
      isChildModuleDdEditing: false,
      showPerPage: false,
      //pagination code
      perpage: 50,
      totRecords: null,
      page: 1,
      isLoading: true,
      fullPage: true,
      ///
    };
  },
  mounted(){
    //fetching services
    this.get_services();

    //fetching panels
    this.get_panel()

    //requires on update page
    this.get_modules_by_parent_id();

    // fetching_http_methods_list
    this.get_http_methods();

    // this.get_panel()
    this.panel_modules()

    // fetching_operations
    this.get_operations();

  },
  computed: {
    //pagination computed methods
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    totPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    getPages: function () {
      return this.$utils.getPages(
        this.lastPage,
        this.currentPage,
        this.totPage
      );
    },
    currentPage: function () {
      return this.page;
    },
    lastPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    }
  },
  methods: {
    //pagination methods
    checkIfOperationExistForModule(type) {
      return this.$utils.checkIfOperationExistForModule(this, type);
    },
    getDataByPage(value) {
      console.log(value);
      this.page = value;
      this.getEndpoints(this.page);
    },
    handlePerPage(value) {
      console.log(value);
      this.perpage = value;
      this.getEndpoints(this.page);
    },
    paginate(n) {
      console.log(n);
      this.page = n === 0 ? 1 : parseInt(this.currentPage) + parseInt(n);
      this.getEndpoints(this.page);
    },
    last(value) {
      console.log(value);
      this.page = this.lastPage;
      this.getEndpoints(this.page);
    },
    /////
    resetSearch() {
      if (this.query != null || this.p_id || this.m_id != null || this.s_id != null) {
        (this.page = 1), (this.query = "");
        this.p_id = "";
        this.m_id = "";
        this.s_id = "";
        this.panel_modules_data = []
        this.isModuleDdEditing = false
        this.getEndpoints();
      }
    },
    searchEndpoints: function () {
      if(this.query==null){
        this.query = ""
      }
      if(this.p_id==null){
        this.p_id = ""
      }
      if(this.m_id==null){
        this.m_id = ""
      }
      if(this.s_id==null){
        this.s_id = ""
      }
      this.getEndpoints(this.page, this.query, this.p_id, this.m_id, this.s_id);
      console.log(this.query + " " + this.p_id + " " + this.m_id + " " + this.s_id);
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.dialog = false;
      this.dialog1 = false;
      this.message1 = "";
      this.upmessage1 = "";
      this.addEndpoint.module_operations = []
      this.updateEndpoint.module_operations = []
      this.deleteItems = [];
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
      this.is_parent_module_not_selected = false;
    },
    remove_from_add_operations(id){
      let modules = this.addEndpoint.module_operations.filter((m)=> m.module_id != id)
      console.log(modules)
      this.addEndpoint.module_operations = modules
    },
    remove_from_update_operations(id){
      let modules = this.updateEndpoint.module_operations.filter((m)=> m.module_id != id)
      // let view = this
      // this.updateEndpoint.module_operations.forEach(function (item) {
      //   let row ={}
      //   if(item.module_id == id){ 
      //     row['module_id'] = item.module_id
      //     row['operation_ids'] = []
      //   }
      //   view.updateEndpoint.updated_module_operations.push(row)
      // })
      console.log(modules)
      this.updateEndpoint.module_operations = modules
    },
    clearData(){
      this.module_id = "";
      this.parent_module_id = "";
      this.operation_ids = [];
      // this.updateEndpoint.panel = ""
      // this.addEndpoint.panel = ""
      this.toggleUpdateModal = false
      this.is_parent_module_not_selected = false
    },
    getEndpoints(page = "", query = "", p_id = "", m_id = "", s_id = "") {
      let view = this;
      const path =
        this.$url("MANAGE_ENDPOINT") +
        "?e_name=" +
        view.query +
        "&p_id=" +
        view.p_id +
        "&m_id=" +
        view.m_id +
        "&s_id=" +
        view.s_id +
        "&page=" +
        view.page;
      this.isLoading = true;
      let check = this;
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: check })
        .get(path)
        .then((res) => {
          console.log(res);
          this.endpointName = res.data.payload.endpoints;
          this.total = this.endpointName.length;
          this.totRecords = res.data.payload.total_count;
          this.pageNo = res.data.payload.pages;
          this.endpoint_data = [];
          if (Array.isArray(this.endpointName) && this.endpointName.length) {
            for (let i = 0; i < this.endpointName.length; i++) {
                let com_module_name = ""
                if (this.endpointName[i].module_list != "") {
                    if (this.endpointName[i].module_list.length) {
                        for (let j = 0; j < this.endpointName[i].module_list.length; j++) {
                          if(j!= 0 && this.endpointName[i].module_list[j]!="")
                              com_module_name = com_module_name + ",  "
                          if(this.endpointName[i].module_list[j]!="")
                              com_module_name = com_module_name + this.endpointName[i].module_list[j]
                          }
                        // module_name = this.endpointName[i].module_name.join(', ');
                    }
                }
              let tempobj = {
                endpoint_id: this.endpointName[i].endpoint_id,
                endpoint_name: this.endpointName[i].endpoint_name,
                endpoint_desc: this.endpointName[i].endpoint_desc,
                module_name: com_module_name,
                panel_name: this.endpointName[i].panel_name,
                service_name: this.endpointName[i].service_name,
                modified_date: "",
              };
              this.endpoint_data.push(tempobj);
              let dateCov;
              dateCov = this.format_date(this.endpointName[i].modified_date);
              this.endpoint_data[i].modified_date = dateCov;
            }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    get_panel() {
      const path = this.$url("PANEL_DATA");
      let check = this
      this.$fetch({ requiresAuth: true, operation : 'view', vueScope: check })
        .get(path).then((res) => {
              if (res.data.payload.panelId.length) {
                res.data.payload.panelId.forEach(function (item) {
                    let a = {
                      text: item[1],
                      value: item[0],
                    };
                    check.panel_data.push(a);
                });
              }
          });
    },
    get_child_modules: function(){
      let parent_module = this.parent_module_id
      let view = this
      view.child_modules = []
      if(parent_module != null && parent_module != 0){
        this.all_module_data.forEach((m)=>{
          if(m.parent_id == parent_module){
            view.child_modules.push({text:m.module_name, value:m.module_id})
          }
        })
      }
      this.isChildModuleDdEditing = true
      // return child_modules
    },
    panel_modules: function(id){
      this.panel_modules_data = []
      let panel_id = id
      if(panel_id != null && panel_id != 0){    
        this.all_module_data.forEach((m)=>{
          if(m.panel_id == panel_id && m.parent_id == 0){
            this.panel_modules_data.push({text:m.module_name, value:m.module_id})
          }
        })
      }
      this.isModuleDdEditing = true
    },
    add_prerequisites() {
      // this.isLoading = true;

      // // fetching_http_methods_list
      // this.get_http_methods();

      // // // fetching_parent modules
      // // this.get_modules_by_parent_id();

      // // fetching_operations
      // this.get_operations();

      // this.isLoading = false;
    },
    add_module_operations() {
      if((this.addEndpoint.panel != "" || this.addEndpoint.panel !=null) && (this.parent_module_id == "" || this.parent_module_id == null))
        {
          this.is_parent_module_not_selected = true
          return
        }else{
          this.is_parent_module_not_selected = false
        }
      let show_modules = {
        parent_module_id: this.parent_module_id,
        module_id: this.module_id,
        operation_ids: this.operation_ids
      }
      let is_duplicate = false
      if(this.addEndpoint.module_operations.length>0){
        for(var i =0 ; i< this.addEndpoint.module_operations.length; i++){
          if(this.addEndpoint.module_operations[i].parent_module_id == this.parent_module_id && 
              JSON.stringify(this.addEndpoint.module_operations[i].operation_ids)==JSON.stringify(this.operation_ids)){
                if(this.addEndpoint.module_operations[i].module_id == undefined)
                    this.addEndpoint.module_operations[i].module_id = ""
                if(this.addEndpoint.module_operations[i].module_id == this.module_id){
                  is_duplicate = true;
                  break;
                }
          }
        }
      }
      if(is_duplicate){
        this.$swal.fire({
              icon: "warning",
              title: "Error",
              text: "Duplicate Mappings are not allowed"
          });
      }else{
          this.addEndpoint.module_operations.push(show_modules);
      }
      this.module_id = "";
      this.operation_ids = [];
      this.parent_module_id = "";
      this.addEndpoint.panel = "";
    },
    get_module_name(id){
      let name = ''
      this.all_module_data.forEach((m)=>{
        if(m.module_id==id){
          name = m.module_name
        }
      })
      return name
    },
    get_panel_name(id){
      let name = ''
      let panel_id;
      this.all_module_data.forEach((m)=>{
        if(m.module_id==id){
          panel_id = m.panel_id
        }
      })
      this.panel_data.forEach((panel)=>{
        if(panel.value==panel_id){
          name = panel.text
        }
      })
      return name
    },
    get_operation_name(ids){
      let name = ''
      ids.forEach((id)=>{
        this.operation_data.forEach((m)=>{
        if(m.value == id){
          if(name == ''){
            name = m.text
          }else{
            name = name+', '+  m.text
          }
        }
      })
      })
      return name
    },
    update_module_operations() {
      if((this.updateEndpoint.panel != "" || this.updateEndpoint.panel !=null) && (this.parent_module_id == "" || this.parent_module_id == null)){
          this.is_parent_module_not_selected = true
          return
        }else{
          this.is_parent_module_not_selected = false
        }
      let module = {
        parent_id: this.parent_module_id,
        module_id: this.module_id,
        operation_ids: this.operation_ids,
      };
      let is_duplicate = false
      if(this.updateEndpoint.module_operations.length>0){
        for(var i =0 ; i< this.updateEndpoint.module_operations.length; i++){
          if(this.updateEndpoint.module_operations[i].parent_id == this.parent_module_id && 
              JSON.stringify(this.updateEndpoint.module_operations[i].operation_ids)==JSON.stringify(this.operation_ids)){
                if(this.updateEndpoint.module_operations[i].module_id == undefined)
                    this.updateEndpoint.module_operations[i].module_id = ""
                if(this.updateEndpoint.module_operations[i].module_id == this.module_id){
                  is_duplicate = true;
                  break;
                }
          }
        }
      }
      if(is_duplicate){
          this.$swal.fire({
              icon: "warning",
              title: "Error",
              text: "Duplicate Mappings are not allowed"
          });
      }else{
        this.updateEndpoint.module_operations.push(module);
      }
      // this.updateEndpoint.updated_module_operations.push(module);
      this.module_id = "";
      this.operation_ids = [];
      this.parent_module_id = "";
      //this.module_data = [];
      this.updateEndpoint.panel = ""
    },
    get_modules_by_parent_id() {
      let check = this;
      if (this.parent_module_id != undefined && this.parent_module_id != 0) {
        this.module_parent_id = this.parent_module_id;
      } else {
        this.module_parent_id = 0;
      }
      const endpoint_url = this.$url("GET_MODULES") + "?id=" + this.module_parent_id;
      let module_id = this.module_parent_id;
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: check })
        .get(endpoint_url)
        .then((res) => {
          let modules = res.data.payload.modules;
          this.total = modules.length;
          if (Array.isArray(modules) && modules.length) {
            modules.forEach((md)=>{
              if(md.parent_id == 0){
                  let tempobj = {
                  value: md.module_id,
                  text: md.module_name,
                };
                if (module_id == 0) {
                  this.parent_module_data.push(tempobj);
                }
              }
              this.all_module_data.push(md)
            })
          }
        });
        this.isChildModuleDdEditing = true
    },
    get_operations() {
      const endpoint_url = this.$url("MODULEOPERATIONS");
      let check = this;
      this.operation_data = [];
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: check })
        .get(endpoint_url)
        .then((res) => {
          let module_operation = res.data.payload.operation;
          this.total = module_operation.length;
          if (Array.isArray(module_operation) && module_operation.length) {
            for (let i = 0; i < module_operation.length; i++) {
              let tempobj = {
                value: module_operation[i][0],
                text: module_operation[i][1],
              };
              this.operation_data.push(tempobj);
            }
          }
        });
    },
    get_services() {
      const path = this.$url("MANAGE_SERVICES");
      let check = this;
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: check })
        .get(path)
        .then((res) => {
          console.log(res);
          this.serviceName = res.data.payload.operation[0];
          this.total = this.serviceName.length;
          if (Array.isArray(this.serviceName) && this.serviceName.length) {
            this.service_data = [];
            let i;
            for (i = 0; i < this.serviceName.length; i++) {
              let tempobj = {
                value: this.serviceName[i].service_id,
                text: this.serviceName[i].name,
              };
              let serch_service_obj = {
                value: this.serviceName[i].service_id,
                text: this.serviceName[i].name,
              };
              this.searchService.push(serch_service_obj);
              this.service_data.push(tempobj);
            }
          }
        });
    },
    get_http_methods() {
      const methods_path = this.$url("HTTP_METHODS");
      this.http_methods = [];
      let check = this;
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: check })
        .get(methods_path)
        .then((res) => {
          console.log(res);
          let http_methods = res.data.payload.http_methods[0];
          this.total = http_methods.length;
          if (Array.isArray(http_methods) && http_methods.length) {
            for (let i = 0; i < http_methods.length; i++) {
              let tempobj = {
                value: http_methods[i][0],
                text: http_methods[i][1],
              };
              this.http_methods.push(tempobj);
            }
          }
        });
    },
    addEndpointData(e) {
      e.preventDefault();
      let check = this;
      this.isLoading = true;
      console.log(this.addEndpoint.endpointName);
      if (
        this.addEndpoint.endpointName != "" &&
        this.addEndpoint.endpointName != null
      ) {
        this.$fetch({ requiresAuth: true, operation: "add", vueScope: check })
          .post(this.$url("MANAGE_ENDPOINT"), this.addEndpoint)
          .then((result) => {
            console.log(result.data.payload);
            this.isLoading = false;
            this.message1 = result.data.payload[0].endpointId;
            this.message2 = result.data.payload[0].endpointName;
            if (this.message2 == null) {
              this.message1 = result.data.payload[0].endpointId;
              this.dialog = true;
            } else {
              this.message1 = null;
              this.dialog = false;
              if (result.data.statusCode == 200 && this.message1 == null) {
                this.$swal
                  .fire({
                    icon: "success",
                    title: "Record(s) added successfully",
                    text: result.data.payload.message,
                  }).then((result) => {
                    if (result) {
                      this.reset();
                      this.getEndpoints(this.page, this.query);
                    } else {
                      // something other stuff
                    }
                  });
              }
            }
            console.log(result);
          });
      }
    },
    addToUpdate(e, atchid) {
      let view = this;
      this.toggleUpdateModal = true;
      this.isLoading = true;
      for (let i = 0; i < this.endpointName.length; i++) {
        let http_methods = [];
        for (let j = 0; j < this.endpointName[i].http_methods.length; j++) {
          http_methods.push(this.endpointName[i].http_methods[j].method_id);
        }
        if (this.endpointName[i].endpoint_id == atchid) {
          view.updateEndpoint.endpointName = this.endpointName[i].endpoint_name;
          view.updateEndpoint.endpoint_slug = this.endpointName[i].endpoint_slug;
          view.updateEndpoint.endpoint_desc = this.endpointName[i].endpoint_desc;
          view.updateEndpoint.endpointId = this.endpointName[i].endpoint_id;
          // view.updateEndpoint.panel =this.endpointName[i].panel_id;
          view.updateEndpoint.panel = ""
          view.updateEndpoint.service = this.endpointName[i].service_id;
          view.module_id = this.endpointName[i].module_id;
          view.operation_ids = this.endpointName[i].operation_ids? this.endpointName[i].operation_ids: []
          console.log("operation_ids: ",this.endpointName[i].operation_ids)
          view.updateEndpoint.module_operations = this.endpointName[i].module_operations
          view.updateEndpoint.http_methods = http_methods;
        }
      }
      this.isLoading = false;
    },
    updateEndpointData(e) {
      e.preventDefault();
      let data = this;
      this.isLoading = true;
      if (
        data.updateEndpoint.endpointName != "" &&
        data.updateEndpoint.endpointName != null
      ) {
        this.$fetch({ requiresAuth: true, operation: "edit", vueScope: data })
          .put(data.$url("MANAGE_ENDPOINT"), data.updateEndpoint)
          .then((result) => {
            data.isLoading = false;
            this.upmessage1 = result.data.payload.endpointId;
            this.upmessage2 = result.data.payload.endpointName;
            if (this.upmessage2 == null) {
              this.upmessage1 = result.data.payload.endpointId;
              this.dialog1 = true;
            } else {
              this.upmessage1 = null;
              this.dialog1 = false;
              if (result.data.statusCode == 200 && this.upmessage1 == null) {
                this.$swal
                  .fire({
                    icon: "success",
                    title: "Record(s) updated successfully",
                    text: result.data.payload.message,
                  })
                  .then((result) => {
                    if (result) {
                      this.reset();
                      this.getEndpoints(this.page, this.query);
                    } else {
                      // something other stuff
                    }
                  });
                this.toggleUpdateModal = false;
              }
            }
            console.log(result);
          });
      }
    },
    addToDelete(e, atchid) {
      this.checkedthis = false;
      //e.target.checked
      if (e.target.checked) {
        if (this.checked_operationId.indexOf(e.target.value) == -1) {
          this.checked_operationId.push(e.target.value);
        }
      } else {
        this.checked_operationId.splice(
          this.checked_operationId.indexOf(e.target.value),
          1
        );
      }
      let checkcedstr = this.checked_operationId.join(",");
      this.checkcedstr = checkcedstr;
      console.log(this.checkcedstr);
    },
    deleteEndpoint(e) {
      e.preventDefault();
      let check = this;
      console.log(this.deleteItems);
      this.isLoading = true;
      this.$fetch({ requiresAuth: true, operation: "delete", vueScope: check })
        .delete(this.$url("MANAGE_ENDPOINT"), {
          params: { id: this.deleteItems },
        })
        .then((response) => {
          check.isLoading = false;
          if (response.data.statusCode == 200) {
            //this.getEndpoints(this.page, this.query);
            this.$swal
              .fire({
                icon: "success",
                title: "Selected record(s) has been deleted",
                text: response.data.payload.message,
              })
              .then((result) => {
                if (result) {
                  this.deleteItems = [];
                  this.getEndpoints(this.page, this.query);
                } else {
                  // something other stuff
                }
              });
          }
        });
    },
    selectAll: function () {
      console.log("y");
      let endpoint = this.endpointName;
      if (this.allSelected == true) {
        if (Array.isArray(endpoint) && endpoint.length) {
          this.deleteItems = [];
          let i;
          for (i = 0; i < endpoint.length; i++) {
            console.log(endpoint[i]);
            this.deleteItems.push(endpoint[i][0]);
          }
        }
      } else if (this.allSelected == false) {
        this.reset();
      }
    },
    select: function () {
      this.allSelected = false;
    },
    submit() {
      this.$v.$touch();
    },
  },
  watch: {
    "$store.state.Rawdata.current_active_module": function (o) {
      if (o) {
        this.getEndpoints(1);
      }
    },
    page: function (ob) {
      //this.getEndpoints(ob);
    },
  },
};
</script>
